import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductCard from '../components/ProductCard';
import { WrapItem, Center, Box, Wrap, Button, Container, Checkbox, Alert, AlertIcon } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { getProducts } from '../redux/actions/productActions';
import { Text } from '@chakra-ui/react';
import { favoriteBrand } from '../favoriteBrandData.js';
import FilterButton from '../components/FilterButton';

const FavoriteBrandScreen = ({ products }) => {
    const { favoritebrand } = useParams();
    const dispatch = useDispatch();
    const [selectedBrands, setSelectedBrands] = useState([]);
    console.log(favoriteBrand,'favoriteBrand')
  

    // Find the brand object based on the URL parameter
    const brandObject = favoriteBrand.find(item => item.link === `/kedvencmarkak/${favoritebrand}`);
    console.log(brandObject,'brandObject')

    // Filter products based on the selected brand
    const filteredProducts = products.filter(product => product.brand === brandObject?.name);
    console.log(filteredProducts);


    useEffect(() => {
        dispatch(getProducts());
    }, [dispatch]);

    return (
        <Box>
            <Box style={{ textAlign: 'center' }} display='flex' justifyContent='center' marginY='20px'>
                <Text fontSize='4xl' fontWeight='bold'>
                    {brandObject ? brandObject.name : 'Termékek'} Termékek
                </Text>
            </Box>
  

            <Wrap spacing={{base:'20px',md:'30px'}} justify='center' minHeight='80vh' mx={{ base: '3', md: '20', lg: '32' }}>
                {filteredProducts.map(product => (
                    <WrapItem key={product._id}>
                        <Center>
                            <ProductCard product={product} />
                        </Center>
                    </WrapItem>
                ))}
            </Wrap>

        </Box>
    );
};

export default FavoriteBrandScreen;
