import {
	Box,
	Badge,
	Flex,
	Heading,
	HStack,
	Icon,
	Image,
	Link,
	Skeleton,
	Stack,
	Wrap, WrapItem,
	useColorModeValue as mode,
	Text,
	Container,
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { Link as ReactLink } from 'react-router-dom';
import { BsPhoneFlip } from 'react-icons/bs';
import { categoryData } from '../categoryData';
import { GiTakeMyMoney } from "react-icons/gi";
import { TbTruckDelivery } from 'react-icons/tb';
import { BsMouse } from "react-icons/bs";
import ScrollToTopButton from '../components/ScrollToButton';
import { useEffect, useRef } from 'react';
import Szabadsag from '../components/Szabadsag';
import ConfirmAgesAlert from '../components/ConfirmAgesAlert';
import CaptionCarousel from '../components/CarouselLandingScreen';
import { useState } from 'react';
import FavoriteBrand from '../components/FavoriteBrand';
import Szolgaltatas from '../components/Szolgaltatas';
import KiemeltKategoria from '../components/KiemeltKategoria';
import Ujdonsag from '../components/Ujdonsag';
const LandingScreen = () => {

	const [isOpen, setIsOpen] = useState(true);
	const [show, setShow] = useState(false)
	const boxRef = useRef(null);

	useEffect(() => {
		// Az alert 3 másodperc elteltével automatikusan bezáródik
		const timeout = setTimeout(() => {
			setIsOpen(false);
		}, 30000);

		// Tisztítjuk a timeout-ot a komponens megunmountolásakor
		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		const scrollToTop = () => {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		};

		scrollToTop();
	}, []);

	const handleClick = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const handleClickToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	return (



		<Box >
			{/* <Flex justifyContent="center" alignItems="center" flexDirection={{ base: 'column', lg: 'row' }}>
				<Box

					mx={{ base: '6', md: '8', lg: '0' }}
					px={{ base: '6', md: '8', lg: '0' }}
					py={{ base: '3', md: '8', lg: '12' }}
					display='flex'
					flexDirection='column'
					alignItems='center'
					textAlign='center'
					mb={{ base: '0', lg: '0' }}
				>
					<Stack spacing={{ base: '8', lg: '10' }} direction={{ base: 'column', lg: 'row' }}>
						<Heading style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} color='white' bg='green.500' rounded='lg' p='8' size='xl' fontWeight='normal'>
							<GiTakeMyMoney style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} />
							<Text fontSize={{ base: '20px', md: '36px' }}>10000 Ft vásárlás felett 3% kedvezmény</Text>

						</Heading>
						<Heading style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} color='white' bg='green.500' rounded='lg' p='8' size='xl' fontWeight='normal'>
							<TbTruckDelivery style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} />
							<Text fontSize={{ base: '20px', md: '36px' }}>Ingyenes házhozszállítás 1 órán belül Hajdúnánás területén hétköznap 12-18 óra között</Text>
						</Heading>
						<Heading style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} color='white' bg='green.500' rounded='lg' p='8' size='xl' fontWeight='normal'>
							<GiTakeMyMoney style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} />
							<Text fontSize={{ base: '20px', md: '36px' }}>15000 Ft vásárlás felett 6% kedvezmény</Text>

						</Heading>
					</Stack>
					<HStack display='flex' flexDirection='column' spacing='' mt='10'> */}

			{/* <Link as={ReactLink} to='' color={mode('red.300', 'yellow.200')}>
						Fedezze fel
					</Link> */}
			{/* <Icon color={mode('red.300', 'yellow.200')} as={FaArrowRight} /> */}

			{/* </HStack>
				</Box>
			</Flex> */}
			<Container maxW="container.xl" my={{ base: '25px', md: '25px' }}>
				<CaptionCarousel />
			</Container>

			{/* <Wrap justify='center' pt={{ base: '0', md: '5' }} pb={{ base: '0', md: '10' }} >
				<BsMouse fontSize='50px' color={mode('#fc8181', '#faf089')} />
			</Wrap> */}
			<KiemeltKategoria />
			<Szolgaltatas />
			<Ujdonsag />
			<Wrap justify='center' pt='10' pb='10'>

				<Text fontWeight='semibold' fontSize='3xl' justify='center'>Fedezze fel termékeinket!</Text>
			</Wrap>
			<Container my={10} maxW='container.xl'>
				<Wrap spacing={{ base: '20px', md: '20px' }} marginBottom='20px' justify='center' wrap="wrap">
					{categoryData.map((category) => (
						<Box
							position='relative'
							as={ReactLink}
							key={category.name}
							w={{ base: 'calc(45% - 10px)', md: '200px' }}
							h='150px'
							p='5'
							bg='white'
							rounded='lg'
							color='green.600'
							boxShadow='md'
							transition='0.3s'
							className="category-button"
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
								textDecoration: 'none',
								border: '2px solid green.500',
								opacity: 0.9,
							}}
							sx={{
								'&:hover': {
									backgroundColor: 'green.100',
								},
								'span': { transition: 'transform 0.3s ease-in-out' },
								'&:hover span': { transform: 'scale(1.2)' },
								'&:hover .badge': { transform: 'none' }
							}}
							to={category.link}
							onClick={handleClick}
						>
							<WrapItem display='flex' justifyContent='center' w='50px' h='50px'>
								<span>{category.svg}</span>
							</WrapItem>

							<Link
								fontSize='15'
								display='flex'
								justifyContent='center'
								style={{ textAlign: 'center' }}
								_hover={{ textDecoration: 'none' }}
								color='green.600'
							>
								{category.name}
							</Link>

							{category.new && (
								<Badge
									className="badge"
									textAlign='center'
									borderTopLeftRadius='5px'
									position='absolute'
									left={{ base: '0px', md: '0px' }}
									top={{ base: '0px', md: '0px' }}
									w={{ base: '60px', md: '95px' }}
									fontSize={{ base: '0.5em', md: '0.8em' }}
									colorScheme='red'
								>
									ÚJDONSÁG
								</Badge>
							)}
						</Box>
					))}
				</Wrap>
			</Container>

			<Wrap>
				<FavoriteBrand />
			</Wrap>

			{show && <Szabadsag isOpen={isOpen} onClose={() => setIsOpen(false)} />}

		</Box>


	)
};

export default LandingScreen;