import { WrapItem,Container, Box, Wrap, Image, Text, Link, Flex, IconButton } from "@chakra-ui/react";


const Szallitas = () => {
    return (
        <Container maxW="container.lg">
            <Box display='flex' justifyContent='center' alignItems='center' marginY='20px'>
                <Text fontSize='4xl' fontWeight='bold'>Szállítási és fizetési információk</Text>

            </Box>
            <Box marginY='20px' marginX='20px'>
                <Text fontSize='xl' fontWeight='bold' mb='20px'>
                    Szállítási információk:</Text>
                <Text fontSize='l' fontWeight='semibold' mb='20px'>
                    Szolgáltatásunk elérhető Hajdúnánás területén, az alábbiak szerint:</Text>
                <Text fontSize='s'>Webáruházunk 12:00 és 18:00 óra között vállalja a kiszállítást 2 órás intervallumokkal, ezen időpont között leadott rendelését 2 órán belül kiszállítjuk. A 18:00 óra után leadott rendelését csak a következő napon tudjuk kiszállítani. Szombaton és Vasárnap nincs kiszállítás.
                  
                </Text>
                <Text fontSize='s' mt='20px'>A házhozszállítás díja 5000Ft feletti rendelés esetén ingyenes, 5000Ft alatti rendeléseket 500Ft kiszállítási díjat számolunk fel</Text>
                <Text fontSize='xl' fontWeight='bold' mt='20px' mb='20px'>
                    Fizetési információk:</Text>
                    <Text fontSize='l' fontWeight='semibold' mb='20px'>
                    Fizetését a rendelés leadásakor, a kiszállítást végző futárnál teheti meg. Elfogadott fizetési módok:</Text>
                    <Text fontSize='s'>● Bankkártyás fizetés: Kérjük, készítse elő a bankkártyáját a fizetéshez.</Text>
                    <Text fontSize='s'>● Készpénzes fizetés: Kérjük, pontos összeggel készüljön a készpénzes fizetéshez.</Text>
            </Box>
        </Container>

    );
}

export default Szallitas;