import {
	Box,
	Button,
	Flex,
	FormControl,
	Heading,
	Radio,
	RadioGroup,
	Spacer,
	Select,
	Collapse,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { setShipping, setPackingValue, cartItemAdd } from '../redux/actions/cartActions';
import { setAddress, setDeliveryTime, setPayment } from '../redux/actions/orderActions';
import TextField from './TextField';
import TextArea from './TextArea';
import { Link as ReactLink } from 'react-router-dom';
import { MdOutlineEdit } from 'react-icons/md';
import axios from 'axios';
import CustomRadio from './CustomRadio'
import { CheckIcon } from '@chakra-ui/icons';
const ShippingInformation = () => {
	const { shipping, packing, cartItems, subtotal } = useSelector((state) => state.cart);
	const { shippingAddress, selectedDeliveryTime } = useSelector((state) => state.order);
	const { userInfo } = useSelector((state) => state.user);
	const [isDeliveryTimeSelected, setDeliveryTimeSelected] = useState(false);

	let discount;
	if (subtotal >= 15000) {
		discount = 0.06;
	} else if (subtotal >= 10000) {
		discount = 0.03;
	} else {
		discount = 0;
	}
	const packingCost = packing ? 150 : 0;
	const shippingCost = subtotal < 5000 ? 500 : 0; // Szállítási költség hozzáadása, ha a subtotal kisebb mint 5000 Ft
	const subtotalAfterDiscount = subtotal - (subtotal * discount);
	const totalAmount = Math.floor((subtotalAfterDiscount + packingCost + shippingCost) / 5) * 5;

	const emailData = {
		userInfo,
		shipping,
		packing,
		cartItems,
		shippingAddress,
		totalAmount,
		selectedDeliveryTime
	};

	const deliveryTimeSlots = Array.from({ length: 6 }, (_, i) => {
		const hour = 12 + i;
		return `${hour}:00 - ${hour + 1}:00`;
	});


	const dispatch = useDispatch();

	const onSubmit = async (values) => {
		dispatch(setAddress(values));
		dispatch(setPayment(values))
		sendOrderConfirmationEmail();
		localStorage.removeItem('selectedDeliveryTime');
	};


	const sendOrderConfirmationEmail = async () => {
		try {
			await axios.post('https://eviabc.hu/api/sendorderconfirmationemail', emailData
				// Include necessary data for the email
			);
			console.log('Email sent successfully');
		} catch (error) {
			console.error('Error sending email:', error);
		}
	};

	return (
		<Formik
			initialValues={{
				address: shippingAddress ? shippingAddress.address : '',
				number: shippingAddress ? shippingAddress.number : '',
				comment: shippingAddress ? shippingAddress.comment : '',
			}}
			validationSchema={Yup.object({
				address: Yup.string().required('Szükségünk van a címedre.').min(2, 'Ez a cím túl rövid.'),
				number: Yup.string().required('Szükségünk van a telefonszámodra.').min(2, 'Ez a telefonszám túl rövid.'),
			})}
			onSubmit={onSubmit}
		>
			{(formik) => (
				<>
					<VStack as='form'>
						<FormControl>
							<TextField name='address' placeholder='Utca, házszám' label='Utca, házszám' />
							<TextField name='number' placeholder='Telefonszám' label='Telefonszám' />
							<TextArea name='comment' placeholder='Megjegyzés szállítási idővel kapcsolatban stb.' label='Megjegyzés' />
						</FormControl>
						<Box w='100%' pr='5'>
							<Heading fontSize='2xl' fontWeight='extrabold' pb={5}>
								Szállítási idő
							</Heading>
							<Button
								position='relative'
								variant='outline'
								colorScheme='green'
								w='100%'
								onClick={() => setDeliveryTimeSelected(!isDeliveryTimeSelected)}
							>
								{selectedDeliveryTime ? selectedDeliveryTime : <Box display='flex'> <Text fontSize={{ base: 'sm', md: 'md' }}> Választhatsz szállítási időt (Nem kötelező)</Text><Box position='absolute' right={{ base: '5px', md: '15px' }}><MdOutlineEdit size='20px' /></Box> </Box>}
							</Button>
							<Collapse in={isDeliveryTimeSelected} animateOpacity>
								<RadioGroup
									ms={1}
									mt={4}
									value={selectedDeliveryTime}
									onChange={(e) => {
										dispatch(setDeliveryTime(e))
										setDeliveryTimeSelected(false);
									}}
								>
									<Stack spacing={3}>
										{deliveryTimeSlots.map((slot) => (
											<CustomRadio key={slot} value={slot} selected={selectedDeliveryTime === slot}>
												{slot}
											</CustomRadio>
										))}
									</Stack>
								</RadioGroup>
							</Collapse>
						</Box>

						<Box w='100%' pr='5'>
							<Heading fontSize='2xl' fontWeight='extrabold' pb={5}>
								Kiszállítási díj
							</Heading>
							<Box><Text>{subtotal < 5000 ? '5.000Ft alatt 500Ft kiszállítási díjat számolunk fel.' : 'Ingyenes'}</Text></Box>
						</Box>
						<Box w='100%' pr='5'>
							<Heading fontSize='2xl' fontWeight='extrabold' mb='5'>
								Fizetési lehetőségek
							</Heading>
							<RadioGroup
								onChange={(value) => {
									// Frissítjük a shipping állapotot a kiválasztott érték alapján
									dispatch(setShipping(value === 'express' ? 14.99 : 4.99));
								}}
								value={shipping === 14.99 ? 'express' : 'withoutExpress'}
							>
								<Stack direction={{ base: 'column', lg: 'row' }} align={{ lg: 'flex-start' }} justify='space-between'>

									{/* Express szállítás opció */}
									<Box position='relative' w='200px'>
										<Radio value='express'>
											{shipping === 14.99 && (
												<CheckIcon
													position='absolute'
													left='-12px'
													color='white'
													bg='green.400'
													borderRadius='50%'
													p={1}
													boxSize={5}
													ml='10px'
													mt='14px'
												/>
											)}
											<Text fontWeight='bold'>Fizetés a helyszínen</Text>
											<Text>Bankkártyával</Text>
										</Radio>
									</Box>

									{/* Normál szállítás opció */}
									<Box position='relative' w='200px'>
										<Radio value='withoutExpress'>
											{shipping === 4.99 && (
												<CheckIcon
													position='absolute'
													left='-12px'
													color='white'
													bg='green.400'
													borderRadius='50%'
													p={1}
													boxSize={5}
													ml='10px'
													mt='14px'
												/>
											)}
											<Text fontWeight='bold'>Fizetés a helyszínen</Text>
											<Text>Készpénzzel</Text>
										</Radio>
									</Box>
								</Stack>
							</RadioGroup>
						</Box>

						<Box w='100%' pr='5'>
							<Heading fontSize='2xl' fontWeight='extrabold' mb='5'>
								Csomagolás
							</Heading>
							<RadioGroup
								onChange={(value) => {
									dispatch(setPackingValue(value === 'packing2'));
								}}
								value={packing ? 'packing2' : 'packing'}
							>
								<Stack mb='5' direction={{ base: 'column', lg: 'row' }} align={{ lg: 'flex-start' }} justify='space-between'>
									<Box position="relative">
										<Radio value='packing'>
											<Text fontWeight='bold'>Nem Szeretném kérni</Text>
										</Radio>
										{packing === false && (
											<CheckIcon
												position='absolute'
												left='-12px'
												color='white'
												bg='green.400'
												borderRadius='50%'
												p={1}
												boxSize={5}
												ml='10px'

											/>
										)}
									</Box>
									<Box position="relative">
										<Radio value='packing2'>
											<Text fontWeight='bold'>Szeretném kérni(+150Ft)</Text>
										</Radio>
										{packing === true && (
											<CheckIcon
												position='absolute'
												left='-12px'
												color='white'
												bg='green.400'
												borderRadius='50%'
												p={1}
												boxSize={5}
												ml='10px'
											/>
										)}
									</Box>
								</Stack>
							</RadioGroup>
						</Box>

					</VStack>
					<Flex alignItems='center' gap='2' direction={{ base: 'column', lg: 'row' }}>
						<Button variant='outline' colorScheme='green' w='100%' as={ReactLink} to='/kosar'>
							Vissza a kosárhoz
						</Button>
						<Button
							variant='outline'
							colorScheme='green'
							w='100%'
							onClick={formik.handleSubmit}>
							Megrendelés
						</Button>
					</Flex>
				</>
			)}
		</Formik>
	);
};

export default ShippingInformation;
