import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: true,
    orderInfo: null,
    orderId: null,
    shippingAddress: JSON.parse(localStorage.getItem('shippingAddress')) ?? null,
    packing: JSON.parse(localStorage.getItem('packing')) ?? false,  
    selectedDeliveryTime: JSON.parse(localStorage.getItem('selectedDeliveryTime')) ?? null, // Betöltés a localStorage-ból
};

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setError: (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        },
        setShippingAddress: (state, { payload }) => {
            state.shippingAddress = payload;
            state.loading = false;
            localStorage.setItem('shippingAddress', JSON.stringify(payload));
        },
        setPacking: (state, { payload }) => {
            state.packing = payload;
        },
        setSelectedDeliveryTime: (state, { payload }) => {
            state.selectedDeliveryTime = payload;
			localStorage.setItem('selectedDeliveryTime', JSON.stringify(payload));
        },
        clearOrder: (state) => {
            state.loading = false;
            state.error = true;
            state.orderInfo = null;
            state.orderId = null;
            state.shippingAddress = JSON.parse(localStorage.getItem('shippingAddress')) ?? null;
            state.packing = false; 
            state.selectedDeliveryTime = null; 
        },
    },
});

export const { setLoading, setError, setShippingAddress, setPacking, clearOrder, setSelectedDeliveryTime } = orderSlice.actions;

export default orderSlice.reducer;
export const orderSelector = (state) => state.order;
