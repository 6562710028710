import { Box, Button, Container, Text, List, ListItem } from "@chakra-ui/react";
import { topBrand } from "../topBrand";
import { Link as ReactLink } from "react-router-dom";
import { motion } from 'framer-motion';

const KiemeltKategoria = () => {
    const MotionBox = motion(Box);

    const handleClickToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Box>
            <Box mb={5}>
                <Text textAlign='center' fontWeight='semibold' fontSize='3xl' justify='center'>Kiemelt Kategóriáink</Text>
            </Box>
            <Box display='flex' gap={3} flexWrap='wrap' justifyContent='center'>
                {topBrand.map((brand) => (
                    <MotionBox
                        as={ReactLink}
                        to={brand.link}
                        key={brand.name}
                        width='350px'
                        height='400px'
                        backgroundImage={brand.image}
                        backgroundSize='400px'
                        backgroundRepeat='no-repeat'
                        backgroundPosition='center'
                        onClick={handleClickToTop}
                        display='flex'
                        justifyContent='flex-end'
                        alignItems='flex-end'
                        p={5}
                        flex='1'
                        whileHover={{
                            backgroundSize: '415px',
                        }}
                        transition={{
                            duration: 0.3,
                            ease: 'easeInOut',
                        }}
                    >
                        <Button
                            bg='green.400'
                            color='white'
                            fontWeight='bold'
                            _hover={{
                                bg: 'green.500',
                                boxShadow: 'md',
                                color: 'whiteAlpha.900',
                            }}

                            border='2px solid'
                            borderColor='whiteAlpha.700'
                            px={6}
                            py={4}
                            borderRadius='md'
                        >
                            {brand.name}
                        </Button>

                    </MotionBox>
                ))}
            </Box>
            <Container my={5} pb={4} border="2px" borderColor='green.600' maxW='container.xl'>
    <Box><Text mb={10} fontSize='lg' textAlign='center'>Miért válassz minket?</Text></Box>
    <Box>
        <List fontSize="md" spacing={1} styleType="disc" ml={4}>
            <ListItem>Könnyen és gyorsan vásárolhatsz webáruházunkban.</ListItem>
            <ListItem>Bármilyen kérdés esetén szívesen segítünk, hogy biztosan a megfelelő terméket válaszd.</ListItem>
            <ListItem>Széles kínálatunkban megtalálod a márkás és kedvező árú termékeket is, mindig diszkont áron!</ListItem>
            <ListItem>Saját raktárkészletről szállítunk, így a termékek gyorsan megérkeznek hozzád.</ListItem>
        </List>
    </Box>
</Container>

        </Box>
    );
}

export default KiemeltKategoria;
