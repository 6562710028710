import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductCard from '../components/ProductCard';
import { WrapItem,useToast,Alert,AlertIcon, Center, Box, Wrap, Button, Container, Checkbox, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { getProducts, resetProductError} from '../redux/actions/productActions';
import Category from '../components/Category';
import { categoryData } from '../categoryData';
import FilterButton from '../components/FilterButton';
import CategoryAlt from '../components/CategoryAlt';
import { updateProduct } from '../redux/actions/adminActions';
const CategoryAltScreen = ({ products }) => {
    const { category, categoryalt } = useParams();
    const { productUpdate } = useSelector((state) => state.product);
    const { favoritesToggled, pagination } = useSelector(state => state.product);
    const dispatch = useDispatch();
    const toast = useToast();
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [displayedBrands, setDisplayedBrands] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const categoryObject = categoryData.find(item => item.link === `/${category}`);

    const toggleFilter = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const brandsInCategory = products && Array.isArray(products)
    ? [...new Set(products.filter(product => product.category === category && product['categoryAlt'] === categoryalt).map(product => product.brand))]
    :[];

    const filteredProducts = products && Array.isArray(products)
     ? ( selectedBrands.length > 0 ?
        products.filter(product =>
            selectedBrands.includes(product.brand) &&
            brandsInCategory.includes(product.brand) &&
            product.category === category &&
            product['categoryAlt'] === categoryalt
        ) :
        products.filter(product => product.category === category && product['categoryAlt'] === categoryalt))
        :[];
        useEffect(() => {
            if (products && Array.isArray(products)) {
                // Itt kaphatod a frissített brandeket
                const brandsInCategory = [...new Set(products.filter(product => product.category === category && product['categoryAlt'] === categoryalt))];
            }
        }, [products, category]);
    

    const paginationButtonClick = (page) => {
        dispatch(getProducts(page));
    };

    useEffect(() => {
        dispatch(getProducts());
        dispatch(resetProductError());
        if (productUpdate) {
            toast({
                description: 'A termék frissítve lett.',
                status: 'success',
                isClosable: true,
            });
        }
    }, [dispatch, toast, productUpdate]);
    

    const handleBrandToggle = (brand) => {
        if (selectedBrands.includes(brand)) {
            setSelectedBrands(selectedBrands.filter(b => b !== brand));
        } else {
            setSelectedBrands([...selectedBrands, brand]);
        }
    };

    return (
        <Box>
            <Category />
            <Box style={{ textAlign: 'center' }} display='flex' justifyContent='center' marginY='20px'>
                <Text fontSize={{base:'lg',md:'4xl'}} fontWeight='bold'>
                    {categoryObject && categoryObject.name}
                </Text>
            </Box>
            {categoryObject && categoryObject.categoryalt && categoryObject.categoryalt.length > 0 && (
                <CategoryAlt categoryAlt={categoryObject.categoryalt} />
            )}
            <Container>
                <Box>
                    <FilterButton onClick={toggleFilter} />
                </Box>
            </Container>

            {categoryObject.info && (
          <Box mt='5'>
            <Container>
              <Alert status='error' rounded='5px'>
                <AlertIcon />
                <Box>
                  <Text fontWeight='bold' textAlign='center'>
                    Kedves Vásárlóink!
                  </Text>
                  <Text textAlign='justify'>
                    Áraink már tartalmazzák a betétdíjat, amennyiben a terméken feltüntetésre került a REPONT 50 Ft jelzés.
                  </Text>
                </Box>
              </Alert>
            </Container>
          </Box>
        )}

            <Box
                style={{
                    transition: 'height 0.3s ease-in-out',
                    overflow: 'hidden',
                    height: isFilterOpen ? 'auto' : '0',
                }}
                display='flex'
                flexWrap='wrap'
                justifyContent='center'
                marginY='20px'
                marginX={{ base: '0%', md: '10%' }}
            >
                {brandsInCategory.map((brand, index) => {
                    const isDisplayed = displayedBrands.includes(brand);
                    const isSelected = selectedBrands.includes(brand);
                    return (
                        <Wrap
                            style={{ marginLeft: '10px', marginRight: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer' }} key={index}>
                            <Checkbox
                                defaultColorScheme="red"
                                colorScheme="red"
                                isChecked={isSelected}
                                onChange={() => {
                                    if (!isDisplayed) {
                                        setDisplayedBrands([...displayedBrands, brand]);
                                    }
                                    handleBrandToggle(brand);
                                }}
                                style={{ marginRight: '5px' }}
                            />
                            <label
                                onClick={() => {
                                    if (!isDisplayed) {
                                        setDisplayedBrands([...displayedBrands, brand]);
                                    }
                                    handleBrandToggle(brand);
                                }}
                                style={{ cursor: 'pointer' }}>{brand}</label>
                        </Wrap>
                    );
                })}
            </Box>
            <Wrap spacing={{base:'10px',md:'30px'}} justify='center' minHeight='80vh' mx={{ base: '3', md: '20', lg: '32' }}>
                {filteredProducts.map(product => (
                    <WrapItem key={product._id}>
                        <Center>
                            <ProductCard product={product} />
                        </Center>
                    </WrapItem>
                ))}
            </Wrap>
            {!favoritesToggled && (
                <Wrap spacing='10px' justify='center' p='5'>
                    <Button colorScheme='cyan' onClick={() => paginationButtonClick(1)}>
                        <ArrowLeftIcon />
                    </Button>
                    {Array.from(Array(pagination.totalPages), (e, i) => {
                        return (
                            <Button
                                colorScheme={pagination.currentPage === i + 1 ? 'cyan' : 'gray'}
                                key={i}
                                onClick={() => paginationButtonClick(i + 1)}>
                                {i + 1}
                            </Button>
                        );
                    })}
                    <Button colorScheme='cyan' onClick={() => paginationButtonClick(pagination.totalPages)}>
                        <ArrowRightIcon />
                    </Button>
                </Wrap>
            )}
        </Box>
    );
}

export default CategoryAltScreen;
