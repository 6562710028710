import { Button, ButtonGroup, Container, Divider, IconButton, Stack, Text, useColorModeValue as mode, Box, Flex, Icon, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { FaFacebook } from 'react-icons/fa';
import { TbShoppingCart } from 'react-icons/tb';
import { Link as ReactLink } from 'react-router-dom';
import ColorModeToggle from './ColorModeToggle';

const Footer = () => {
    const handleClickToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Box w='100%' color={mode('gray.200', 'gray.100')} bg={mode('gray.800', 'gray.900')} pt={8} pb={4}>
            <Container maxW='7xl'>
                {/* Fő stack az alap elrendezéshez */}
                <Stack spacing='10' direction={{ base: 'column', md: 'row' }} align='center' justify='space-around'>
                    <Flex onClick={handleClickToTop} alignItems='center' as={ReactLink} to='/'>
                        <Icon as={TbShoppingCart} h='10' color={mode('yellow.300', 'yellow.400')} />
                        <Text fontSize='2xl' fontWeight='extrabold' ml={2}>Évi ABC</Text>
                    </Flex>

                    {/* Elérhetőségek szekció */}
                    <Stack spacing='6' alignItems={{ base: 'center', md: 'start' }}>
                        <Text fontSize='lg' fontWeight='bold' color='gray.400'>Elérhetőségek</Text>
                        <Stack h='150px' spacing='3' align={{ base: 'center', md: 'start' }}>
                            <Text color='gray.500'>Cím: 4080 Hajdúnánás, Sarló utca 45.</Text>
                            <Text color={mode('gray.400', 'gray.400')}>
                                Telefonszám: <a href="tel:+36702074102">+36 70 207 4102</a>
                            </Text>
                            <Text color='gray.500'>Email: <a href="mailto:t0csa91@gmail.com">t0csa91@gmail.com</a></Text>
                        </Stack>
                    </Stack>

                  
                    {/* Nyitvatartás szekció */}
<Stack spacing='6' alignItems={{ base: 'center', md: 'start' }}>
    <Text fontSize='lg' fontWeight='bold' color='gray.400'>Nyitvatartás</Text>
    <Tabs variant='enclosed' colorScheme='yellow'>
        <TabList>
            <Tab>WEBÁRUHÁZ</Tab>
            <Tab>ÜZLET</Tab>
        </TabList>
        <TabPanels>
            <TabPanel h='120px'>
                <Flex justifyContent='space-between' w='100%'>
                    <Text>Hétfő - Péntek</Text>
                    <Text>12-18</Text>
                </Flex>
            </TabPanel>
            <TabPanel h='120px'>
                <Flex justifyContent='space-between' w='100%'>
                    <Text>Hétfő - Péntek</Text>
                    <Text>6 - 20</Text>
                </Flex>
                <Flex justifyContent='space-between' w='100%'>
                    <Text>Szombat</Text>
                    <Text>6 - 16</Text>
                </Flex>
                <Flex justifyContent='space-between' w='100%'>
                    <Text>Vasárnap</Text>
                    <Text>7 - 13</Text>
                </Flex>
            </TabPanel>
        </TabPanels>
    </Tabs>
</Stack>

                </Stack>

                {/* Alsó linkek */}
                <Stack spacing='4' direction={{ base: 'column', md: 'row' }} justify='center' pt={8} pb={4}>
                    <Button as={ReactLink} to='/altalanos-szerzodesi-feltetelek' fontWeight='400' color='gray.400' variant='link'>Általános szerződési feltételek</Button>
                    <Button as={ReactLink} to='/szallatasi-es-fizetesi-informaciok' fontWeight='400' color='gray.400' variant='link'>Szállítási és fizetési információk</Button>
                    <Button as={ReactLink} to='/adatkezelesi-tajekoztato' fontWeight='400' color='gray.400' variant='link'>Adatkezelési tájékoztató</Button>
                </Stack>

                <Divider />

                {/* Copyright és közösségi média */}
                <Stack direction={{ base: 'column', md: 'row' }} align='center' justify='space-between' pt={1} pb={{base:'10',md:'0'}}>
                    <Text fontSize='sm' color='gray.500'>&copy; {new Date().getFullYear()} Évi ABC. Minden jog fenntartva.</Text>
                    <ButtonGroup variant='ghost'>
                        <IconButton as='a' href='https://www.facebook.com/eviabc2024/' icon={<FaFacebook />} color='gray.400' />
                        <ColorModeToggle />
                    </ButtonGroup>
                </Stack>
            </Container>
        </Box>
    );
}

export default Footer;
