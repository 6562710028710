import React, { useState, useEffect } from 'react';
import { Button, Flex, Heading, Stack, Text, useColorModeValue as mode } from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link as ReactLink } from 'react-router-dom';

const OrderSummary = ({ checkoutSreen = false }) => {
    const { subtotal, shipping, packing } = useSelector((state) => state.cart);
    // const [disableCheckout, setDisableCheckout] = useState(false);

    let discount;
    if (subtotal >= 15000) {
        discount = 0.06;
    } else if (subtotal >= 10000) {
        discount = 0.03;
    } else {
        discount = 0;
    }

    // Csomagolás költség
    const packingCost = packing ? 150 : 0;
    const shippingCost = subtotal < 5000 ? 500 : 0; // Szállítási költség hozzáadása, ha a subtotal kisebb mint 5000 Ft
    const subtotalAfterDiscount = subtotal - (subtotal * discount);
    const totalAmount = Math.floor((subtotalAfterDiscount + packingCost + shippingCost) / 5) * 5;
    

    // useEffect(() => {
    //     if (totalAmount < 5000) {
    //         setDisableCheckout(true);
    //     } else {
    //         setDisableCheckout(false);
    //     }
    // }, [totalAmount]);

    return (
        <Stack
            minWidth='300px'
            spacing='8'
            borderWidth='1px'
            borderColor={mode('green.500', 'green.100')}
            rounded='lg'
            padding='8'
            w='full'>
            <Heading size='md'>Rendelési összefoglaló</Heading>
            <Stack spacing='6'>
                <Flex justify='space-between'>
                    <Text fontWeight='medium' color={mode('gray.600', 'gray.400')}>
                        Összeg
                    </Text>
                    <Text fontWeight='medium'>{subtotal} Ft</Text>
                </Flex>
                <Flex justify='space-between'>
                    <Text fontWeight='medium' color={mode('gray.600', 'gray.400')}>
                        Kiszállítási díj
                    </Text>
                    <Text fontSize='15px' fontWeight='medium'>
                        {subtotal < 5000 ? '500Ft' : 'Nincs kiszállítási díj'}
                    </Text>
                </Flex>
                <Flex justify='space-between'>
                    <Text fontWeight='medium' color={mode('gray.600', 'gray.400')}>
                        Kedvezmény
                    </Text>
                    <Text fontWeight='medium'>
                        {subtotal >= 15000 ? '6%' : (subtotal >= 10000 ? '3%' : 'Nincs kedvezmény')}
                    </Text>
                </Flex>
                <Flex justify='space-between'>
                    <Text fontWeight='medium' color={mode('gray.600', 'gray.400')}>
                        Fizetés
                    </Text>
                    <Text fontWeight='medium'>
                        {shipping && parseFloat(shipping) === 4.99 ? 'Készpénzzel' : 'Bankkártyával'}
                    </Text>
                </Flex>
                <Flex justify='space-between'>
                    <Text fontWeight='medium' color={mode('gray.600', 'gray.400')}>
                        Csomagolás
                    </Text>
                    <Text fontWeight='medium'>
                        {packing ? 'Csomagolással(+150Ft)' : 'Csomagolás nélkül'}
                    </Text>
                </Flex>
                <Flex justify='space-between'>
                    <Text fontSize='xl' fontWeight='extrabold'>
                        Teljes összeg
                    </Text>
                    <Text fontWeight='medium'>{totalAmount} Ft</Text>
                </Flex>
            </Stack>
            {/* {totalAmount < 5000 && (
                <Text color="green.500" textAlign='center' fontSize="sm">
                    *5000 Ft alatt nem tudjuk teljesíteni a kiszállítást
                </Text>
            )} */}
            <Button
                hidden={checkoutSreen}
                as={ReactLink}
                // to={disableCheckout ? '' : '/penztar'}
                to='/penztar'
                colorScheme='green'
                size='lg'
                rightIcon={<FaArrowRight />}
                // isDisabled={disableCheckout}
                >
                Pénztár
            </Button>
        </Stack>
    );
};

export default OrderSummary;
