'use client'

import React from 'react'
import {
    Box,
    IconButton,
    useBreakpointValue,
    useColorMode as mode,
    Stack,
    Heading,
    Text,
    Container,
} from '@chakra-ui/react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import Slider from 'react-slick'
import { GiTakeMyMoney, } from 'react-icons/gi'
import { TbTruckDelivery } from 'react-icons/tb'
// Settings for the slider


export default function CaptionCarousel() {
    const [slider, setSlider] = React.useState(null)

    const top = useBreakpointValue({ base: '60%', md: '50%' })
    const side = useBreakpointValue({ base: '10px', md: '40px' })

    const slidesToShow = useBreakpointValue({ base: 1, md: 3 })
    const dotsToShow = useBreakpointValue({base:false,md:true})
    const settings = {
    dots: dotsToShow,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: slidesToShow,  // Három kártya egyszerre
    slidesToScroll: 1,  // Egy kártya lapozásnál
}

    const cards = [ 
        {
            image: '/images/Facebookpost2.jpeg',
        },
        {
            image: '/images/2024-10-21akcio2.jpeg',
        },
        {
            image: '/images/nyeremenyjatek.png',
        },
        // {
        //     title: <GiTakeMyMoney style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} />,
        //     title2:'KEDVEZMÉNY',
        //     text: "10000 Ft vásárlás felett 3% kedvezmény",
        // },
        {
            image: '/images/kiszallitastedej.png',
        },
        {
            image: '/images/kedvezmeny10k.png',
        },
        {
            image: '/images/nyeremenyjatek.png',
        },
        {
            image: '/images/kedvezmeny15k.png',
        },
       
        // {
        //     title: <GiTakeMyMoney style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} />,
        //     title2:'KEDVEZMÉNY',
        //     text: "15000 Ft vásárlás felett 6% kedvezmény",
           
        // },
        {
            image: '/images/kiszallitashajdunanas.png',
        },
        // {
        //     title: <TbTruckDelivery style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} />,
        //     title2:'HAJDÚNÁNÁS',
        //     text: "Ingyenes házhozszállítás 1 órán belül Hajdúnánás területén hétköznap 12-18 óra között",
          
        // },
        // {
        //     title: <TbTruckDelivery style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} />,
        //     title2:'TEDEJ',
        //     text: "500Ft Házhozszállítási díjjal vállaljuk 5000Ft feletti vásárlás esetén Tedej területén. Hétfőn és Szerdán 17:30-ig várjuk a rendeléseket és 18óra után szállítjuk ki a megadott címre.",
           
        // },

    ];

    return (
        <Box position={'relative'}  >
            <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />

            <IconButton
                aria-label="left-arrow"
                variant="ghost"
                position="absolute"
                left={side}
                top={top}
                transform={'translate(0%, -50%)'}
                zIndex={2}
                onClick={() => slider?.slickPrev()}>
                <BiLeftArrowAlt size="40px" />
            </IconButton>
            <IconButton
                aria-label="right-arrow"
                variant="ghost"
                position="absolute"
                right={side}
                top={top}
                transform={'translate(0%, -50%)'}
                zIndex={2}
                onClick={() => slider?.slickNext()}>
                <BiRightArrowAlt size="40px" />
            </IconButton>
            <style>
              
            </style>

            <Slider   {...settings} ref={(slider) => setSlider(slider)}>
                {Array.isArray(cards) && cards.map((card, index) => (
                    <Box
                    
                        key={index}
                        height={{ base: '400px', md: '500px' }}  // A kártyák magasságának beállítása
                        // A kártyák teljes szélességet kihasználják
    
                        position="relative"
                        backgroundPosition='bottom'
                        backgroundRepeat="no-repeat"
                        backgroundSize="cover"
                      
                        // backgroundImage={`url(${card.image})`} 
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Container size="container.xl" height={{base:'200px', md:'250px'}} position="relative">
                            <Stack
                                spacing={3}
                                w={'full'}
                                maxW={'lg'}
                                height={{base:'400px', md:'500px'}}
                                textAlign="center"
                                backgroundColor="green.400" 
                                backgroundImage={`url(${card.image})`} 
                                backgroundPosition="center"
                                backgroundRepeat="no-repeat"
                                backgroundSize="cover"
                                padding="20px"
                                rounded="10px"
                            >
                                <Heading display={'flex'} justifyContent={'center'} fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }} color="white">
                                    {card.title}
                                </Heading>
                                <Heading display={'flex'} justifyContent={'center'} fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }} color="white">
                                    {card.title2}
                                </Heading>
                                <Text fontSize={{ base: 'md', lg: 'lg' }} color="white">
                                    {card.text}
                                </Text>
                            </Stack>
                        </Container>
                    </Box>
                ))}
            </Slider>
        </Box>
    )
}
