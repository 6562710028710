import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import ProductsScreen from './screens/ProductsScreen';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Header from './components/Header';
import LandingScreen from './screens/LandingScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import Footer from './components/Footer';
import LoginScreen from './screens/LoginScreen';
import EmailVerificationScreen from './screens/EmailVerificationScreen';
import PasswordResetScreen from './screens/PasswordResetScreen';
import RegistrationScreen from './screens/RegistrationScreen';
import axios from 'axios';
import { VStack, Spinner } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CheckoutScreen from './screens/CheckoutScreen';
import YourOrdersScreen from './screens/YourOrdersScreen';
import CancelScreen from './screens/CancelScreen';
import SuccessScreen from './screens/SuccessScreen';
import AdminConsoleScreen from './screens/AdminConsoleScreen';
import CategoryScreen from './screens/CategoryScreen';
import { getProducts } from './redux/actions/productActions';
import { useDispatch, useSelector } from 'react-redux';
import HotDeals from './screens/HotDeals';
import Contact from './screens/Contact';
import SearchScreen from './screens/SearchScreen';
import Favorites from './screens/Favorites';
import Aszf from './screens/Aszf';
import AdatkezelesiTajekoztato from './screens/AdatkezelesiTajekoztat';
import Szallitas from './screens/Szallitas';
import CategoryAltScreen from './screens/CategoryAltScreen';
import FavoriteBrandScreen from './screens/FavoriteBrandScreen';
import AdminNewProduct from './screens/AdminNewProduct';
import Profile from './screens/Profile';
import LastOrder from './screens/LastOrder';

function App() {
  const dispatch = useDispatch();
  const [googleClient, setGoogleClient] = useState(null);
  const { loading, error, products } = useSelector((state) => state.product);
  const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL})

//   useEffect(() => {
//     console.log('Sending request to backend...');
//     axiosInstance.get('/api/products')
//         .then(response => {
//             console.log('Received response from backend:', response.data);
         
//         })
//         .catch(error => {
//             console.error('Error from backend:', error);
        
//         });
// }, []);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  useEffect(() => {
    const fetchGoogleKey = async () => {
      const { data: googleId } = await axios.get('/api/config/google');
      setGoogleClient(googleId);
    };
    fetchGoogleKey();
  }, []);

  const theme = extendTheme({
    styles: {
      global: (props) => ({
        body: {
          // bg: props.colorMode ==='white' && 'green',
          // bg: props.colorMode ==='dark' && 'green',
        },
      }),
    },
  });

  return (
    <ChakraProvider theme={theme}>
      {!googleClient ? (
        <VStack pt='37vh'>
          <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
        </VStack>
      ) : (
        <GoogleOAuthProvider clientId={googleClient}>
          <Router>
            <Header />
            <main>
              <Routes>
                <Route path='/termekek' element={<ProductsScreen />} />
                <Route path='/akciok' element={<HotDeals products={products} />} />
                <Route path='/' element={<LandingScreen />} />
                <Route path='/termekek/:id' element={<ProductScreen />} />
                <Route path='/kosar' element={<CartScreen />} />
                <Route path='/bejelentkezes' element={<LoginScreen />} />
                <Route path='/regisztracio' element={<RegistrationScreen />} />
                <Route path='/email-verify/:token' element={<EmailVerificationScreen />} />
                <Route path='/password-reset/:token' element={<PasswordResetScreen />} />
                <Route path='/penztar' element={<CheckoutScreen />} />
                <Route path='/torles' element={<CancelScreen />} />
                <Route path='/rendelesitortenet' element={<YourOrdersScreen />} />
                <Route path='/sikeres' element={<SuccessScreen />} />
                <Route path='/admin-console' element={<AdminConsoleScreen />} />
                <Route path='/admin-newproduct' element={<AdminNewProduct />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/lastorder' element={<LastOrder />} />
                <Route path='/kedvencmarkak/:favoritebrand' element={<FavoriteBrandScreen products={products} loading={loading} error={error} />} />
                <Route path='/:category' element={<CategoryScreen products={products} loading={loading} />} />
                <Route path='/:category/:categoryalt' element={<CategoryAltScreen products={products} />} />
                <Route path='/kapcsolat' element={<Contact />} />
                <Route path='/kereses/:id' element={<SearchScreen />} />
                <Route path='/kedvencek' element={<Favorites />} />
                <Route path='/altalanos-szerzodesi-feltetelek' element={<Aszf />} />
                <Route path='/adatkezelesi-tajekoztato' element={<AdatkezelesiTajekoztato />} />
                <Route path='/szallatasi-es-fizetesi-informaciok' element={<Szallitas />} />
              </Routes>
            </main>
            <Footer />
          </Router>
        </GoogleOAuthProvider>
      )}
    </ChakraProvider>
  );
}

export default App;
