import { Alert,Text,useToast, AlertTitle, AlertIcon, AlertDescription, Box, Button, Center, Wrap, WrapItem } from '@chakra-ui/react';
import { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../components/ProductCard';
import { getProducts,getCategory,resetProductError } from '../redux/actions/productActions';
import Category from '../components/Category';

const HotDeals = () => {
    const dispatch = useDispatch();
    const { loading, error, products, favoritesToggled } = useSelector((state) => state.product);
    const [isNewProducts, setIsNewProducts] = useState([]);
    const { productUpdate } = useSelector((state) => state.product);
    const toast = useToast();

    useEffect(() => {
        const newProducts = products.filter(product => product.productIsNew);
        setIsNewProducts(newProducts);
    }, [products]);

    useEffect(() => {
        dispatch(getProducts());
        dispatch(resetProductError());
        if (productUpdate) {
            toast({
                description: 'A termék frissítve lett.',
                status: 'success',
                isClosable: true,
            });
        }
    }, [dispatch, toast, productUpdate]);


    return ( 
        <>
            <Category/>
            <Box display='flex' justifyContent='center' marginY='20px'>
                <Text fontSize='4xl' fontWeight='bold'>Akciós termékeink</Text>
            </Box>
            {products.length >= 1 && (
                <Box mb={10}>
                   <Wrap spacing={{base:'10px',md:'30px'}} justify='center' minHeight='80vh' mx={{ base: '3', md: '20', lg: '32' }}>
                        {isNewProducts.map(product => (
                            <WrapItem key={product._id}>
                                <Center >
                                    <ProductCard product={product} loading={loading} />
                                </Center>
                            </WrapItem>
                        ))}
                    </Wrap>
                </Box>
            )}
        </>
    );
}
 
export default HotDeals;
