import { Alert, Text, AlertTitle,useToast, AlertIcon, AlertDescription, Box, Button, Center, Wrap, WrapItem } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../components/ProductCard';
import { getProducts, getCategory,resetProductError } from '../redux/actions/productActions';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import Category2 from '../components/Category2';
import ScrollToTopButton from '../components/ScrollToButton';
const ProductsScreen = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { loading, error, products, pagination, favoritesToggled,productUpdate } = useSelector((state) => state.product);


  useEffect(() => {
		dispatch(getProducts());
		dispatch(resetProductError());

		if (productUpdate) {
			toast({
				description: 'A termék frissítve lett.',
				status: 'success',
				isClosable: true,
			});
		}
	}, [dispatch, toast, productUpdate]);

  const handleClickToTop = () => {
		window.scrollTo({
		  top: 0,
		  behavior: 'smooth'
		});
	  };

    const handleBothClick = (callback) => {
      return () => {
        callback();
        handleClickToTop();
      };
    };


  useEffect(() => {
    dispatch(getProducts(1));
  }, [dispatch]);

  const paginationButtonClick = (newPage) => {
    let nextPage = pagination.currentPage;
    if (newPage === 'prev') {
      nextPage = Math.max(1, nextPage - 1);
    } else if (newPage === 'next') {
      nextPage = Math.min(pagination.totalPages, nextPage + 1);
    } else {
      nextPage = newPage;
    }
    dispatch(getProducts(nextPage));
  };

  return (
    <>
      <Category2 />
      {products.length >= 1 && (
        <Box>
          <Box display='flex' justifyContent='center' marginY='20px'>
            <Text fontSize='4xl' fontWeight='bold'>Termékeink</Text>

          </Box>
          <Wrap spacing={{base:'20px',md:'30px'}} justify='center' minHeight='80vh' mx={{ base: '3', md: '20', lg: '32' }}>
            {error ? (
              <Alert status='error'>
                <AlertIcon />
                <AlertTitle>Sajnáljuk!</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            ) : (
              products.map((product) => (
                <WrapItem key={product._id}>
                  <Center >
                    <ProductCard product={product} loading={loading} />
                  </Center>
                </WrapItem>
              ))
            )}
          </Wrap>
         
            {!favoritesToggled && (
              <Wrap spacing='10px' justify='center' p='5'>
                <Button colorScheme='red' onClick={handleBothClick(() => paginationButtonClick('prev'))}>
                  <ArrowLeftIcon />
                </Button>
                {Array.from(Array(pagination.totalPages), (e, i) => {

                  let firstPage = Math.max(1, pagination.currentPage - 3);
                  let lastPage = Math.min(pagination.totalPages, pagination.currentPage + 3);


                  if (pagination.currentPage < 4) {
                    lastPage = Math.min(7, pagination.totalPages);
                  } else if (pagination.currentPage > pagination.totalPages - 4) {
                    firstPage = Math.max(1, pagination.totalPages - 6);
                  }


                  if (i === firstPage - 1) {
                    if (firstPage > 1) {
                      return (
                        <>
                          <Button
                            colorScheme={pagination.currentPage === 1 ? 'red' : 'gray'}
                            key={1}
                            onClick={handleBothClick(() => paginationButtonClick(1))}
                          >
                            {1}
                          </Button>
                          <span key="ellipsis1">...</span>
                        </>
                      );
                    }
                  } else if (i === lastPage) {
                    if (lastPage < pagination.totalPages) {
                      return (
                        <>
                          <span key="ellipsis2">...</span>
                          <Button
                            colorScheme={pagination.currentPage === pagination.totalPages ? 'red' : 'gray'}
                            key={pagination.totalPages}
                            onClick={handleBothClick(() => paginationButtonClick(pagination.totalPages))}
                          >
                            {pagination.totalPages}
                          </Button>
                        </>
                      );
                    }
                  } else if (i >= firstPage - 1 && i < lastPage) {
                    return (
                      <Button
                        colorScheme={pagination.currentPage === i + 1 ? 'red' : 'gray'}
                        key={i}
                        onClick={handleBothClick(() => paginationButtonClick(i + 1))}
                      >
                        {i + 1}
                      </Button>
                    );
                  }
                  return null;
                })}
                <Button colorScheme='red' onClick={handleBothClick(() => paginationButtonClick('next'))}>
                  <ArrowRightIcon />
                </Button>
              </Wrap>
            )}

         
        </Box>
      )}
    </>
  );
};

export default ProductsScreen;