import { Box, Container, Text, List, ListItem } from "@chakra-ui/react";
import { FaTruck, FaCreditCard, FaBoxOpen } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
const Szolgaltatas = () => {
    return (
        <Box bg="green.800">
            <Container
                py={10}
                color="white"
                maxW="container.xl"
                display="flex"
                flexDirection={{ base: "column", md: "row" }}
                flexWrap="wrap"
                justifyContent={{ base: "center", md: "space-between" }}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={4}
                    minHeight="100px" 
                >
                    <Box border="2px" p={3} rounded="50%">
                        <FaTruck size="40px" />
                    </Box>
                    <Box minW={{base:'250px',md:'200px'}}>
                        <Text fontWeight="bold">Csomagátvétel</Text>
                        <List fontSize="sm" spacing={1} styleType="disc" ml={4}>
                            <ListItem>Személyes átvétel</ListItem>
                            <ListItem>Házhozszállítás</ListItem>
                        </List>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={4}
                    minHeight="100px" 

                >
                    <Box border="2px" p={3} rounded="50%">
                        <FaCreditCard size="40px" />
                    </Box>
                    <Box minW={{base:'250px',md:'200px'}}>
                        <Text fontWeight="bold">Fizetési módok</Text>
                        <List fontSize="sm" spacing={1} styleType="disc" ml={4}>
                            <ListItem>Személyesen Készpénzzel</ListItem>
                            <ListItem>Személyesen Bankkártyával</ListItem>
                        </List>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={4}
                    minHeight="100px" 
                >
                    <Box border="2px" p={3} rounded="50%">
                        <FaBoxOpen size="40px" />
                    </Box>
                    <Box minW={{base:'250px',md:'200px'}}>
                        <Text fontWeight="bold">Szállítási helyek</Text>
                        <List fontSize="sm" spacing={1} styleType="disc" ml={4}>
                            <ListItem>Hajdúnánás</ListItem>
                            <ListItem>Tedej</ListItem>
                        </List>
                    </Box>
                    
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={4}
                    minHeight="100px" 
                >
                    <Box border="2px" p={3} rounded="50%">
                        <GiTakeMyMoney size="40px" />
                    </Box>
                    <Box minW={{base:'250px',md:'200px'}}>
                        <Text fontWeight="bold">Kedvezmények</Text>
                        <List fontSize="sm" spacing={1} styleType="disc" ml={4}>
                            <ListItem>5.000Ft felett Ingyenes kiszállítás</ListItem>
                            <ListItem>10.000Ft felett 3% kedvezmény</ListItem>
                            <ListItem>15.000Ft felett 6% kedvezmény</ListItem>
                        </List>
                    </Box>
                    
                </Box>
            </Container>
        </Box>
    );
};

export default Szolgaltatas;
