import { Radio, Text, Box } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const CustomRadio = ({ value, selected, children }) => {
  return (
    <Radio
      value={value}
      _checked={{
        bg: 'green.400',
        color: 'white',
        borderColor: 'green.400',
      }}
      _hover={{
        bg: 'green.50',
      }}
    >
      <Box ps={3} display='flex' justifyContent='space-between' alignItems='center' w='280px'>
        <Text
          fontSize='lg'
          _hover={{
            cursor: 'pointer',
          }}
          fontWeight={selected ? 'bold' : 'normal'}
        >
          {children}
        </Text>
        <Text fontWeight={selected ? 'bold' : 'normal'}>Szabad</Text>

      
        {selected && (
          <CheckIcon
          position='absolute'
          left='-12px'
            color='white'
            bg='green.400'
            borderRadius='50%'
            p={1}
            boxSize={5}
            ml={3}
          />
        )}
      </Box>
    </Radio>
  );
};

export default CustomRadio;
