export const topBrand = [
    {
        "name":"Téli termékek",
        "image":"/images/mikulascsomag.png",
        "link":"/szezon"
    },
    {
        "name":"Zöldségek, Gyümölcsök",
        "image":"/images/zoldseggyumolcs.jfif",
        "link":"/zoldseg_gyumolcs"
    },
    {
        "name":"Chipsek, Rágcsálnivalók",
        "image":"/images/chipsek.jfif",
        "link":"/chips"
    },
    {
        "name":"Édességek",
        "image":"/images/edessegek.jfif",
        "link":"/edesseg"
    },
    {
        "name":"Tejtermékek",
        "image":"/images/tejtermekek.jfif",
        "link":"/tejtermekek_felvagottak"
    },
]