import { categoryData } from "../categoryData";
import { Link, Link as ReactLink } from "react-router-dom";
import { Box, Wrap, Image, Text, Container,useBreakpointValue } from "@chakra-ui/react";
import { favoriteBrand } from "../favoriteBrandData";
import Slider from "react-slick";

const handleClickToTop = () => {

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

const FavoriteBrand = () => {
    const slidesToShow = useBreakpointValue({ base: 3, md: 8 })

    const settings = {
        dots: false,
        arrow: false,
        infinite: true,
        speed: 10000,
        autoplay: true,
        autoplaySpeed: 0, 
        cssEase: "linear", 
        slidesToShow: slidesToShow, 
        slidesToScroll: 1,
        pauseOnHover: true,
    };

    return (
        <Container mb={5} maxW='container.xl'>
        <Box>
            <Box display='flex' justifyContent='center'>
                <Wrap my={3}>
                    <Text fontSize='3xl' fontWeight='bold' textAlign='center'>Kedvelt forgalmazott márkáink</Text>
                </Wrap>
            </Box>
            <Box px={5}>
                <Slider {...settings}>
                    {favoriteBrand.map((brand, index) => (
                        <Box 
                            key={index}
                            width='60px' 
                            height='60px' 
                            display='flex' 
                            justifyContent='center' 
                            alignItems='center' 
                            onClick={handleClickToTop} 
                            as={Link} 
                            to={`${brand.link}`}
                            sx={{ flexShrink: 0 }}
                        >
                            <Box 
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                width='100%' 
                                height='100%' 
                            >
                                <Image
                                    maxW='60px'
                                    maxH='60px' 
                                    objectFit="contain" 
                                    filter="grayscale(1)"
                                    _hover={{ transition: "all 0.3s ease", filter: "grayscale(0)" }}
                                    src={brand.image}
                                />
                            </Box>
                        </Box>
                    ))}
                </Slider>
            </Box>
        </Box>
    </Container>
    
    );
}

export default FavoriteBrand;
