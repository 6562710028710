import React from 'react';
import axios from 'axios';
import { TbTruckDelivery } from "react-icons/tb";
import { MdManageSearch } from "react-icons/md";
import { TbListSearch } from "react-icons/tb";
import { LiaSearchengin } from "react-icons/lia";
import {
	ChevronDownIcon,
	CloseIcon,
	HamburgerIcon,
	SearchIcon,
} from '@chakra-ui/icons';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { MdOutlineAddModerator } from "react-icons/md";
import { TbShoppingCart } from 'react-icons/tb';
import { logout } from '../redux/actions/userActions';
import { FaSearchPlus } from "react-icons/fa";
import {
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerHeader,
	DrawerBody,
	DrawerFooter,
	Popover,
	PopoverBody,
	PopoverTrigger,
	PopoverContent,
	Box,
	Flex,
	HStack,
	Icon,
	Stack,
	Text,
	useColorModeValue as mode,
	useDisclosure,
	AlertDescription,
	Alert,
	AlertIcon,
	AlertTitle,
	Divider,
	Image,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Spacer,
	useToast,
	Collapse
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BsPhoneFlip } from 'react-icons/bs';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { MdOutlineFavorite, MdOutlineFavoriteBorder } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import NavLink from './NavLink';
import ColorModeToggle from './ColorModeToggle';
import { BiUserCheck, BiLogInCircle } from 'react-icons/bi';
import { toggleFavorites } from '../redux/actions/productActions';
import { googleLogout } from '@react-oauth/google';
import { FaBasketShopping } from "react-icons/fa6";
import { searchProducts } from '../redux/actions/searchActions';
import ScrollToTopButton from './ScrollToButton';
import ScrollToTopOnEnter from './ScrollToTopOnEnter';
import { categoryData } from '../categoryData';
import { Link } from 'react-router-dom';
import MobileNavItem from './MobileNavItem.jsx'
// Links for navigation
const Links = [
	{ name: 'Termékek', icon: <HamburgerIcon style={{ fontSize: '17px', marginLeft: '2px' }} />,icon2: <SearchIcon style={{ fontSize: '17px', marginLeft: '2px' }} /> },,
	{ name: 'Akció', route: '/akciok' },
	{ name: 'Kapcsolat', route: '/kapcsolat' },
];


const Header = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const dispatch = useDispatch();
	const { favoritesToggled } = useSelector((state) => state.product);
	const { cartItems } = useSelector((state) => state.cart);
	const { userInfo } = useSelector((state) => state.user);
	const toast = useToast()
	const navigate = useNavigate();
	const [showBanner, setShowBanner] = useState(userInfo ? !userInfo.active : false)
	const [searchValue, setSearchValue] = useState(localStorage.getItem('searchValue') || "");
	const [autocompleteOptions, setAutocompleteOptions] = useState([]);
	const [selectedProductId, setSelectedProductId] = useState(null);
	const [openAutoComplete, setOpenAutoComplete] = useState(false);

	const handleClickToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	const handleBothClick = () => {
		handleSearchSubmit();
		handleClickToTop();
	};

	useEffect(() => {
		if (userInfo && !userInfo.active) {
			setShowBanner(true)
		}
	}, [favoritesToggled, dispatch, userInfo]);

	const logoutHandler = () => {
		googleLogout()
		dispatch(logout());
		toast({
			description: 'Kijelentkeztél.',
			status: 'success',
			isClosable: 'true',
		})
	}

	const handleTextClick = () => {
		onClose();
		handleClickToTop();
	};

	const handleSearchChange = async (query) => {
		setSearchValue(query);
		setOpenAutoComplete(true);
		try {
			const { data } = await axios.get(`/api/autocomplete?t=${query}`);
			setAutocompleteOptions(data);
		} catch (error) {
			console.error('Error fetching autocomplete options:', error);
		}
	};

	const handleSearchSubmit = async () => {
		try {
			if (searchValue.trim() !== '') {
				await dispatch(searchProducts(searchValue));
				navigate(`/kereses/${searchValue}`);
				setOpenAutoComplete(false);
			}
		} catch (error) {
			console.error('Error during search:', error);
			toast({
				title: 'Hiba történt a keresés közben',
				status: 'error',
				isClosable: true,
			});
		}
	};

	const handleProductClick = (productId) => {
		setSelectedProductId(productId);
		navigate(`/termekek/${productId}`);
		setSearchValue('');
		setOpenAutoComplete(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (event.target.closest('.autocomplete-box') === null) {
				setOpenAutoComplete(false);
			}
		};
	
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);
	

	return (
		<>
			<Box style={{ paddingLeft: '25px' }} bg='black'>
				<Flex display={{ base: 'none', md: 'flex' }} alignItems='center'>
					<TbTruckDelivery style={{ width: '30px', height: '30px' }} color='white' />
					<Text pt='10px' pb='10px' ps='10px' color='white'>
						Hajdúnánás területén 5000Ft felett vállaljuk ingyenesen a kiszállítást minden hétköznap 12-18 óra között.
					</Text>
				</Flex>
			</Box>

			<Box bg={mode(`green.400`, 'gray.900')} px={{ base: '0', md: '4' }} position='sticky' top='0' zIndex='sticky'>

				<Flex h='16' alignItems='center' justifyContent='space-between'>
					{/* <Flex alignItems='center'>
						<IconButton
							bg='parent'
							size='md'
							icon={isOpen ? <CloseIcon /> : <MdManageSearch style={{ fontSize: '25px' }} />}
							onClick={isOpen ? onClose : onOpen}
						/>
					</Flex> */}
					<HStack spacing='8' alignItems='center'>
						<div style={{ display: 'flex', minWidth: '90px', paddingLeft: '15px' }}>
							<Box onClick={handleClickToTop} alignItems='center' display='flex' as={ReactLink} to='/'>
								 <Icon as={FaBasketShopping} h='5' w='4' color={mode('white', 'yellow.200')} />
								<Text as='b' alignItems='center' color='white'> Évi ABC</Text> 
								{/* <Box w='80px' h='50px' overflow='hidden'>
									<Image
										src='/images/logo2.png'
										w='100%'
										h='100%'
										objectFit='cover' // Ez biztosítja, hogy a kép kitöltse a dobozt
										transform='scale(1.4)' // A kép nagyításához
										transformOrigin='center' // A nagyítás középpontra igazítása
									/>
								</Box> */}
							</Box>
						</div>
						<HStack as='nav'  spacing='4' display={{ base: 'none', md: 'flex' }}>
							<div style={{ display: 'flex', minWidth: '90px' }}>
								{Links.map((link) => (
									<NavLink
										route={link.route}
										key={link.route}
										onClick={() => {
											if (link.name === 'Termékek') {
		
												isOpen ? onClose() : onOpen();
											}
										}}
									>
										<Text display='flex' alignItems='center' fontWeight='medium'>{link.icon}{link.name}</Text>
									</NavLink>

								))}
							</div>
						</HStack>
						<HStack display={{ base: 'none', md: 'flex' }}>
							<Box>
								<IconButton icon={<TbShoppingCart size='20px' />} as={ReactLink} to='/kosar' variant='ghost' />
								{cartItems.length > 0 && (
									<Text fontWeight='bold' fontStyle='italic' position='absolute' ml='26px' mt='-6' fontSize='sm'>
										{cartItems.length}
									</Text>
								)}
							</Box>

							<ColorModeToggle />
							<IconButton
								onClick={handleClickToTop}
								as={ReactLink} to='/kedvencek'
								icon={favoritesToggled ? <MdOutlineFavorite size='20px' /> : <MdOutlineFavoriteBorder size='20px' />}
								variant='ghost'
							/>
						</HStack>
					</HStack>
					<Box w={{ base: '50%', md: '40%' }} position="relative">
						<ScrollToTopOnEnter>
							<InputGroup color='black'>
								<Input
									type="text"
									bg='white'
									placeholder="Keresés"
									color='black'
									border='1px'
									value={searchValue}
									onChange={(e) => handleSearchChange(e.target.value)}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											handleSearchSubmit();
										}
									}}
								/>
								<InputRightElement>
									<SearchIcon
										aria-label="Keresés"
										cursor='pointer'
										icon={<SearchIcon />}
										onClick={handleBothClick}
									/>
								</InputRightElement>
							</InputGroup>
						</ScrollToTopOnEnter>
						{openAutoComplete && searchValue && autocompleteOptions.length > 0 && (
							<Box className="autocomplete-box" bg="white" boxShadow="lg" p={2} position="absolute" top="calc(100% + 8px)" left="0" width="100%">
								{autocompleteOptions.map((option, index) => (
									<Box display='flex' alignItems='center' mb={{ base: 0, md: '10px' }} key={index} p={2} _hover={{ bg: "gray.200" }} onClick={() => handleProductClick(option._id)}>
										<Flex color='black' fontSize={{ base: '10px', md: '16px' }}>
											{option.brand} {option.name}
											<Image
												w='35px'
												h='35px'
												objectFit='cover'
												src={option.images[0]}
												alt={option.name}
												fallbackSrc='https://via.placeholder.com/250'
											/>
										</Flex>
									</Box>
								))}
							</Box>
						)}
					</Box>

					<Flex alignItems='center'>
						{userInfo ? (
							<Menu>
								<MenuButton rounded='full' variant='link' cursor='pointer' minW='0'>
									<HStack>
										{userInfo.googleImage ? (<Image borderRadius='full' boxSize='40px' src={userInfo.googleImage} referrerPolicy='no-referrer' />) : (<BiUserCheck size='30' />)}
										<ChevronDownIcon />
									</HStack>
								</MenuButton>
								<ScrollToTopButton>
									<MenuList>
										<HStack>
											<Text pl='3' as='i'>{userInfo.email}</Text>
											{userInfo.googleId && <FcGoogle />}
										</HStack>
										<Divider py='' />
										<MenuItem as={ReactLink} to='/rendelesitortenet'>Rendelési előzmények</MenuItem>
										<MenuItem as={ReactLink} to='/profile'>Profil</MenuItem>
										{userInfo.isAdmin && (
											<>
												<MenuDivider />
												<MenuItem as={ReactLink} to='/admin-newproduct'>
												<MdOutlineAddModerator />
													<Text ml='2'>Új Termék hozzáadása</Text>
												</MenuItem>
												<MenuItem as={ReactLink} to='/lastorder'>
												<MdOutlineAddModerator />
													<Text ml='2'>Legutolsó Rendelés</Text>
												</MenuItem>
												<MenuItem as={ReactLink} to='/admin-console'>
													<MdOutlineAdminPanelSettings />
													<Text ml='2'>Admin felület</Text>
												</MenuItem>
											</>
										)}
										<MenuDivider />
										<MenuItem onClick={logoutHandler}>Kijelentkezés</MenuItem>
									</MenuList>
								</ScrollToTopButton>
							</Menu>
						) : (
							<Menu>
								<ScrollToTopButton>
									<MenuButton as={IconButton} variant='ghost' cursor='pointer' icon={<BiLogInCircle size='25px' />} />
									<MenuList>
										<MenuItem as={ReactLink} to='/bejelentkezes' p='2' fontWeight='400' variant='link'> Bejelentkezés</MenuItem>
										<MenuDivider />
										<MenuItem as={ReactLink} to='/regisztracio' p='2' fontWeight='400' variant='link'> Regisztráció</MenuItem>
									</MenuList>
								</ScrollToTopButton>
							</Menu>
						)}
					</Flex>
				</Flex>



				<Drawer isOpen={isOpen} placement="left" onClose={onClose} size={{ base: 'full', md: 'md' }}>
					<DrawerOverlay />
					<DrawerContent>
						<DrawerHeader >
							<Flex justifyContent="space-between" alignItems="center">
								<Text fontSize="lg" fontWeight="bold">Menü</Text>
								<IconButton
									icon={<CloseIcon />}
									onClick={onClose}
								/>
							</Flex>
						</DrawerHeader>

						<DrawerBody>
							<Box display='flex' justifyContent='center' mb='4'>
								<ColorModeToggle />
								<Box display='flex'>
									<Box position='relative'>
										<IconButton
											onClick={onClose}
											icon={<TbShoppingCart size='20px' />}
											as={ReactLink}
											to='/kosar'
											variant='ghost'
										/>
										{cartItems.length > 0 && (
											<Text fontWeight='bold' fontStyle='italic' position='absolute' ml='26px' mt='-6' fontSize='sm'>
												{cartItems.length}
											</Text>
										)}
									</Box>
									<IconButton
										as={ReactLink} to='/kedvencek'
										onClick={handleClickToTop}
										icon={<MdOutlineFavorite size='20px' />}
										variant='ghost'
									/>
								</Box>
							</Box>
							<Text fontWeight='bold' fontSize='2xl'>Kategóriák</Text>
							<Stack as='nav' spacing='1'>
								{categoryData.map((category) => (
									<MobileNavItem
										key={category.name}
										label={category.name}
										image={category.image}
										children={category.categoryalt}
										onClose={onClose}
										handleClickToTop={handleClickToTop}
									/>
								))}
							</Stack>
						</DrawerBody>

						<DrawerFooter display='flex' justifyContent='space-around'>
							<Text fontSize="sm" textAlign='center'>🛒 Rendelj és élvezd a kényelmet otthonodban! 🏠</Text>
						</DrawerFooter>
					</DrawerContent>
				</Drawer>


			</Box>

			<Box bg={mode(`green.400`, 'gray.900')} display={{ md: 'none' }} px='4' position='fixed' bottom='0' zIndex='sticky' width='100%'>
				<Flex h='16' alignItems='center' justifyContent='center'>
					<HStack spacing='8' alignItems='center'>
						<HStack as='nav' spacing='4'>
							{Links.map((link) => (
								<NavLink route={link.route} key={link.route} onClick={() => {
									if (link.name === 'Termékek') {
								
										isOpen ? onClose() : onOpen();
									}
								}}>
									<Text display='flex' alignItems='center' onClick={handleTextClick} fontWeight='medium'>{link.name}{link.icon2}</Text>
								</NavLink>
							))}
							<Box>
								<IconButton onClick={handleTextClick} icon={<TbShoppingCart size='20px' />} as={ReactLink} to='/kosar' variant='ghost' />
								{cartItems.length > 0 && (
									<Text fontWeight='bold' fontStyle='italic' position='absolute' ml='26px' mt='-6' fontSize='sm'>
										{cartItems.length}
									</Text>
								)}
							</Box>
							<IconButton
								as={ReactLink} to='/kedvencek'
								onClick={handleTextClick}
								icon={<MdOutlineFavorite size='20px' />}
								variant='ghost'
							/>
						</HStack>
					</HStack>
				</Flex>
			</Box>

			{userInfo && !userInfo.active && !userInfo.googleId && showBanner && (
				<Box>
					<Alert status='warning'>
						<AlertIcon />
						<AlertTitle>Email címe nincs megerősítve!</AlertTitle>
						<AlertDescription>Meg kell erősítenie az e-mail címét.</AlertDescription>
						<Spacer />
						<CloseIcon cursor={'pointer'} onClick={() => setShowBanner(false)} />
					</Alert>
				</Box>
			)}
		</>
	);
};

export default Header;
