// cartSlice.js

import { createSlice } from '@reduxjs/toolkit';

const calculateSubtotal = (cartState) => {
  let result = 0;
  cartState.forEach((item) => (result += item.qty * item.price));
  return result;
};

export const initialState = {
  loading: false,
  error: null,
  cartItems: JSON.parse(localStorage.getItem('cartItems')) ?? [],
  shipping: JSON.parse(localStorage.getItem('shipping')) ?? Number(0),
  subtotal: localStorage.getItem('cartItems') ? calculateSubtotal(JSON.parse(localStorage.getItem('cartItems'))) : 0,
  packing: JSON.parse(localStorage.getItem('packing')) ?? false,
};

const updateLocalStorage = (cart) => {
  localStorage.setItem('cartItems', JSON.stringify(cart));
  localStorage.setItem('subtotal', JSON.stringify(calculateSubtotal(cart)));

  // localStorage.setItem('packing', JSON.stringify(cart.packing));
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    cartItemAdd: (state, { payload }) => {
      const existingItem = state.cartItems.find((item) => item.id === payload.id);
    
      if (existingItem) {
        state.cartItems = state.cartItems.map((item) => (item.id === existingItem.id ? payload : item));
      } else {
        // Alapértelmezett packing érték beállítása, ha undefined
        const newItem = {
          ...payload,
          packing: payload.packing !== undefined ? payload.packing : false, // Packing alapértelmezett false
        };
        state.cartItems = [...state.cartItems, newItem];
      }
      state.loading = false;
      state.error = null;
      updateLocalStorage(state.cartItems);
      state.subtotal = Number(calculateSubtotal(state.cartItems));
    },
    cartItemRemoval: (state, { payload }) => {
      state.cartItems = [...state.cartItems].filter((item) => item.id !== payload);
      updateLocalStorage(state.cartItems);
      state.subtotal = calculateSubtotal(state.cartItems);
      state.loading = false;
      state.error = null;
    },
    setShippingCosts: (state, { payload }) => {
      state.shipping = payload;
      localStorage.setItem('shipping', payload);
    },
    clearCart: (state) => {
      localStorage.removeItem('cartItems');
      localStorage.removeItem('shipping');
      localStorage.removeItem('subTotal');
      localStorage.removeItem('packing');
      state.cartItems = [];
      state.shipping = Number(4.99);
      state.subtotal = 0;
      state.loading = false;
      state.error = null;
    },
    // Hozzáadja a setPacking akciót
    setPacking: (state, { payload }) => {
      state.packing = payload;
      localStorage.setItem('packing', JSON.stringify(payload)); // Mentse el a packing állapotot
    },
  },
});

export const { setError, setLoading, cartItemAdd, cartItemRemoval, setShippingCosts, setPacking, clearCart } = cartSlice.actions;

export default cartSlice.reducer;

export const cartSelector = (state) => state.cart;
