import { Container, useToast, Text, Box } from "@chakra-ui/react";
import { getAllOrders, deleteOrder, resetErrorAndRemoval, setDelivered } from '../redux/actions/adminActions';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const LastOrder = () => {
    const { userInfo } = useSelector((state) => state.user);
    const location = useLocation();
    const { error, loading, orders, deliveredFlag, orderRemoval } = useSelector((state) => state.admin);
    const toast = useToast();
    const dispatch = useDispatch();
    console.log(orders)
    useEffect(() => {
        dispatch(getAllOrders());
        dispatch(resetErrorAndRemoval());
        if (orderRemoval) {
            toast({
                description: 'Rendelés törölve lett.',
                status: 'success',
                isClosable: true,
            });
        }

        if (deliveredFlag) {
            toast({
                description: 'Rendelés kiszállítva.',
                status: 'success',
                isClosable: true,
            });
        }
    }, [dispatch, toast, orderRemoval, deliveredFlag]);

    return userInfo && userInfo.isAdmin ? (
        <Container>
              <Text my={10} fontSize="2xl" fontWeight="bold" textAlign="center">Legutolsó Rendelés</Text>
            {orders && orders.length > 0 ? (
                orders.slice(-1).map((order) => (
                    <Box key={order._id} borderWidth="1px" borderRadius="lg" p={4} mb={4}>

                        <Text fontWeight="bold">Rendelés ID: {order._id}</Text>
                        <Text>Dátum: {new Date(order.createdAt).toLocaleString('hu-HU', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}</Text>
                        <Text>Felhasználó: {order.username}</Text>
                        <Text>Szállítási idő: {order.selectedDeliveryTime}</Text>
                        <Text>Szállítási cím: {order.shippingAddress.address}</Text>
                        <Text>Teljes összeg: {order.totalPrice} Ft</Text>
                        <Text>Megjegyzés : {order.shippingAddress.comment}</Text>
                        <Text>Fizetés: {order && parseFloat(order.shippingPrice) === 4.99 ? 'Fizetés a helyszínen' : 'Fizetés bankkártyával'}</Text>


                        <Text color={order.isDelivered ? 'green.500' : 'red.500'}>
                            {order.isDelivered ? 'Kiszállítva' : 'Szállítás alatt'}
                        </Text>


                        <Box mt={4}>
                            <Text fontSize="lg" fontWeight="bold">Rendelt tételek:</Text>
                            {order.orderItems.map((item) => (
                                <Box key={item._id} p={2} borderWidth="1px" borderRadius="md" mt={2}>
                                    <Text><strong>Termék neve:</strong> {item.name}</Text>
                                    <Text><strong>Márka:</strong> {item.brand}</Text>
                                    <Text><strong>Darabszám:</strong> {item.qty}</Text>
                                    <Text><strong>Ár:</strong> {item.price} Ft</Text>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ))
            ) : (
                <Text my={10} textAlign='center'>Nincsenek rendeléseid.</Text>
            )}
        </Container>
    ) : (
        <Navigate to='/' replace={true} state={{ from: location }} />
    );
}

export default LastOrder;