import React, { useState } from 'react';
import { Box,useDisclosure, Image, Select, FormControl, FormLabel, Switch, Text, Textarea, Badge, Flex, IconButton, Skeleton, useToast, Tooltip, Input, Button, useColorModeValue as mode } from '@chakra-ui/react';
import { BiExpand } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { addToFavorites, removeFromFavorites } from '../redux/actions/productActions';
import { useSelector } from 'react-redux';
import { MdOutlineFavorite, MdOutlineFavoriteBorder } from 'react-icons/md';
import { FaTrashCan } from "react-icons/fa6";
import { Link as ReactLink } from 'react-router-dom';
import { addCartItem } from '../redux/actions/cartActions';
import { useEffect,useRef } from 'react';
import { TbShoppingCartPlus } from 'react-icons/tb';
import ConfirmAgesAlert from './ConfirmAgesAlert';
import AdultImage from '../Image/age18.png'
import ScrollToTopButton from './ScrollToButton';
import { MdOutlineEdit } from 'react-icons/md';
import { DeleteIcon } from '@chakra-ui/icons';
import { deleteProduct, updateProduct } from '../redux/actions/adminActions';
import {
	getProducts,
	resetProductError,
} from '../redux/actions/productActions';
import { removeCartItem } from '../redux/actions/cartActions';
import { keyframes } from '@emotion/react';
import { categoryData } from "../categoryData";
import ConfirmRemovalAlert from './ConfirmRemovalAlert';

const ProductCard = ({ product, loading }) => {
	const dispatch = useDispatch();
	const cancelRef = useRef();
	const {isOpen,  onOpen, onClose } = useDisclosure();
	const { favorites } = useSelector((state) => state.product);
	const [isShown, setIsShown] = useState(false);
	const { cartItems } = useSelector((state) => state.cart);
	const toast = useToast();
	const [cartPlusDisabled, setCartPlusDisabled] = useState(false);
	const [isIsOpen, setIsIsOpen] = useState(false);
	const { userInfo } = useSelector((state) => state.user);
	const [brand, setBrand] = useState(product.brand);
	const [name, setName] = useState(product.name);
	const [category, setCategory] = useState(product.category);
	const [stock, setStock] = useState(product.stock);
	const [price, setPrice] = useState(product.price);
	const [productIsNew, setProductIsNew] = useState(product.productIsNew);
	const [visszavalto, setVisszavalto] = useState(product.visszavalto);
	const [description, setDescription] = useState(product.description);
	const [subtitle, setSubtitle] = useState(product.subtitle);
	const [imageOne, setImageOne] = useState(product.images[0]);
	const [imageTwo, setImageTwo] = useState(product.images[1]);
	const [stripeId, setStripeId] = useState(product.stripeId);
	const [categoryAlt, setCategoryAlt] = useState(product.categoryAlt)
	const [isEditing, setIsEditing] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [cartQty, setCartQty] = useState(0);
	const cartItem = cartItems.find(item => item.id === product._id);
	const hoverBackgroundColor = mode('green.100', 'green.900');
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [isOpenCategory, setIsOpenCategory] = useState(false);
	const toggleDropdown = () => setIsOpenCategory(!isOpenCategory);

	const openDeleteConfirmBox = () => {
		onOpen();
	};

	const handleSelect = (categoryItem) => {
		setSelectedCategory(categoryItem.name);
		setIsOpenCategory(false);
	};

	useEffect(() => {
		const foundCategory = categoryData.find(cat => cat.link === `/${category}`);
		setSelectedCategory(foundCategory || null);
		if (!foundCategory) setCategoryAlt('');
	}, [category]);


	const slideIn = keyframes`
  			0% {
    transform: translateX(100%);  
    opacity: 0;
  			}
 		 100% {
    transform: translateX(0);  
    opacity: 1;
  		}
			`;

	const buttonSlideIn = keyframes`
  		0% {
    transform: translateX(20px);
    opacity: 0;
 		 }
 		 100% {
    transform: translateX(0);
    opacity: 1;
  		}
				`;


	useEffect(() => {
		if (cartItem) {
			setCartQty(cartItem.qty);
		}
	}, [cartItem]);

	const handleQtyChange = (e) => {
		let value = parseInt(e.target.value, 10);

		if (value > product.stock) {
			value = product.stock;
			toast({
				description: 'Elérted a maximális készletet.',
				status: 'warning',
				isClosable: true,
			});
		} else if (value < 1 || isNaN(value)) {
			value = 1;
		}

		setCartQty(value);
		dispatch(addCartItem(product._id, value));
	};

	const increaseQty = () => {
		// Ha van már a kosárban, növeli a mennyiséget
		if (cartQty < product.stock) {
			const newQty = cartQty + 1;
			setCartQty(newQty);
			dispatch(addCartItem(product._id, newQty));
		} else {
			toast({
				description: 'Nem tudsz többet hozzáadni, nincs elég készlet.',
				status: 'warning',
				isClosable: true,
			});
		}
	};

	const decreaseQty = () => {
		// Csökkenti a mennyiséget, ha több mint 1
		if (cartQty > 1) {
			const newQty = cartQty - 1;
			setCartQty(newQty);
			dispatch(addCartItem(product._id, newQty));
		} else {
			// Ha 0, eltávolítja a kosárból
			setCartQty(0);
			dispatch(removeCartItem(product._id));
		}
	};


	const handleEditClick = () => {
		setIsEditing((prev) => !prev);
	};
	const onSaveProduct = () => {
		dispatch(
			updateProduct(
				brand,
				name,
				category,
				stock,
				price,
				product._id,
				productIsNew,
				description,
				subtitle,
				stripeId,
				imageOne,
				imageTwo,
				categoryAlt,
				visszavalto

			)
		);
		setIsEditing(!isEditing)
	};



	useEffect(() => {
		const item = cartItems.find((cartItem) => cartItem.id === product._id);
		if (item && item.qty === product.stock) {
			setCartPlusDisabled(true);
		} else {
			setCartPlusDisabled(false);
		}
	}, [cartItems, product.stock]);

	const addItem = (id) => {
		if (cartItems.some((cartItem) => cartItem.id === id)) {
			const item = cartItems.find((cartItem) => cartItem.id === id);
			dispatch(addCartItem(id, item.qty + 1));
		} else {
			dispatch(addCartItem(id, 1));
		}
		toast({
			description: 'A tétel hozzá lett adva.',
			status: 'success',
			isClosable: true,
		});
	};


	const handleAddToCartClick = () => {
		if (product.category === 'alkohol' || product.category === 'sor_bor') {
			setIsIsOpen(true);
		} else {
			addItem(product._id);
		}
	};

	const handleClickToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};



	return (
		<Skeleton isLoaded={!loading}>
			<Box
				position='relative'
				_hover={{ backgroundColor: hoverBackgroundColor }}
				sx={{
					'&:hover': {
						backgroundColor: hoverBackgroundColor,
					},
					'img': { transition: 'transform 0.3s ease-in-out' },
					'&:hover img': { transform: 'scale(1.01)' },
				}}
				transition='0.5s'
				borderWidth='1px'
				width={{ base: '170px', md: '220px', lg: '250px' }}
				maxW={{ base: '220px', md: '250px' }}
				overflow='hidden'
				p={{ base: '3', md: '4' }}
				shadow='md'

			>
				{product.visszavalto && (
					<Image zIndex={1} src="/images/visszavalto.webp" w='60px' position='absolute' ml={{ base: '60px', sm: '60px', md: '120px' }} rounded='50%' colorScheme='purple' />
				)}
				{product.productIsNew && (
					<Badge zIndex={1} position='absolute' ml='2' colorScheme='purple'>
						AKCIÓ!
					</Badge>
				)}
				{userInfo && userInfo.isAdmin && (
					<IconButton
						icon={<MdOutlineEdit size='20px' />}
						colorScheme='blue'
						size='sm'
						position="absolute"
						top="10px"
						right="35px"
						onClick={handleEditClick}
						zIndex={1}
					/>
				)}

				<Box
					as={userInfo && userInfo.isAdmin ? 'div' : ReactLink}
					onClick={userInfo && userInfo.isAdmin ? undefined : handleClickToTop}
					to={`/termekek/${product._id}`}
					display="flex"
					flexDirection="column"
					alignItems="center"
				>
					<Image
						onMouseEnter={() => setIsShown(true)}
						onMouseLeave={() => setIsShown(false)}
						src={product.images[isShown && product.images.length === 2 ? 1 : 0]}
						fallbackSrc="https://via.placeholder.com/150"
						alt={product.name}
						height={{ base: '100px', md: '200px' }}
						alignSelf="center"
					/>

					{product.stock <= 0 ? (
						<Badge alignSelf="flex-start" colorScheme="red">Elfogyott</Badge>
					) : product.stock - (cartItems.find(item => item.id === product._id)?.qty || 0) <= 0 ? (
						<Badge alignSelf="flex-start" colorScheme="red">Elfogyott</Badge>
					) : product.stock < 5 ? (
						<Badge alignSelf="flex-start" colorScheme="yellow">Csak {product.stock - (cartItems.find(item => item.id === product._id)?.qty || 0)} db maradt</Badge>
					) : (
						<Badge alignSelf="flex-start" colorScheme="green">Elérhető</Badge>
					)}



					{isEditing ? (
						<Box display="flex" borderColor={mode('green.900', 'green.300')} flexDirection="column" alignItems="center" mt={2}>
							<IconButton
						icon={<DeleteIcon size='20px' />}
						colorScheme='red'
						size='sm'
						position="absolute"
						top="10px"
						left="35px"
						onClick={openDeleteConfirmBox}
						zIndex={1}
					/>
							<Input size='sm' value={imageOne} onChange={(e) => setImageOne(e.target.value)} />
							<Input size='sm' value={imageTwo} onChange={(e) => setImageTwo(e.target.value)} />
							<Select
								placeholder="Kategóriák"
								border='1px'
								borderColor={mode('black','green.200')}
								rounded='0'
								value={category}
								onChange={(e) => {
									const selectedCategoryValue = e.target.value.replace('/', '');
									setCategory(selectedCategoryValue);
									setCategoryAlt('');
								}}
							>
								{categoryData.map((categoryItem) => (
									<option key={categoryItem.name} value={categoryItem.link.replace('/', '')}>
										{categoryItem.name.length > 10 ? `${categoryItem.name.slice(0, 25)}...` : categoryItem.name}
									</option>
								))}
							</Select>
							{selectedCategory && selectedCategory.categoryalt && (
								<Select
									placeholder="Alkategóriák"
									border='1px'
									borderColor={mode('black','green.200')}
									rounded='0'
									value={categoryAlt}
									onChange={(e) => {
										const selectedAltCategoryValue = e.target.value.split('/').pop();
										setCategoryAlt(selectedAltCategoryValue);
									}}
								>
									{selectedCategory.categoryalt.map((altCategory) => (
										<option key={altCategory.name} value={altCategory.link.split('/').pop()}>
											{altCategory.name}
										</option>
									))}
								</Select>

							)}
							<Input size='sm' value={brand} onChange={(e) => setBrand(e.target.value)} />
							<Input size='sm' value={name} onChange={(e) => setName(e.target.value)} />
							<Input size='sm' value={price} onChange={(e) => setPrice(e.target.value)} />
							<Input size='sm' value={stock} onChange={(e) => setStock(e.target.value)} />

							<FormControl display='flex' justifyContent='space-between' alignItems='center'>
								<FormLabel htmlFor='productIsNewFlag' mb='0' fontSize='sm'>
									<Badge rounded='full' px='1' mx='1' fontSize='0.8em' colorScheme='green' >
										AKCIÓ
									</Badge>
								</FormLabel>
								<Switch id='productIsNewFlag' onChange={() => setProductIsNew(!productIsNew)} isChecked={productIsNew} />
							</FormControl>
							<FormControl display='flex' justifyContent='space-between' alignItems='center'>
								<FormLabel htmlFor='visszavaltoflag' mb='0' fontSize='sm'>

									<Badge rounded='full' px='1' mx='1' fontSize='0.8em' colorScheme='green'>
										VISSZAVÁLTÓ
									</Badge>

								</FormLabel>
								<Switch id='visszavaltoflag' onChange={() => setVisszavalto(!visszavalto)} isChecked={visszavalto} />
							</FormControl>



							<Button mt={2} colorScheme="green" size="sm" onClick={onSaveProduct}>
								Mentés
							</Button>
						</Box>
					) : (
						<>
							<Text
								noOfLines={1}
								fontSize="xl"
								fontWeight="semibold"
								mt="2"
								textAlign="center"
							>
								{product.brand}
							</Text>

							<Text
								style={{
									textAlign: "center",
									height: "50px",
									overflow: "hidden",
								}}
								fontSize="md"
								fontWeight=""
								mt="2"
							>
								{product.name}
							</Text>

							<Text noOfLines={1} fontSize="md" color="gray.600">
								{product.subtitle}
							</Text>

							<Flex justify='center' alignItems='center' mt='2'>
								<Text fontSize='xl' fontWeight='semibold' color='green.300'>
									{product.price} Ft
								</Text>
							</Flex>
						</>
					)}
				</Box>
				<Flex w={{ base: '150px', sm: '140px', md: '200px' }} flexWrap={{ base: 'wrap', md: 'nowrap' }} justify={{ base: 'space-evenly', md: 'space-between' }} mt='2'>
					{favorites.includes(product._id) ? (
						<IconButton
							icon={<MdOutlineFavorite size='20px' />}
							colorScheme='green'
							size='sm'
							onClick={() => dispatch(removeFromFavorites(product._id))}
						/>
					) : (
						<IconButton
							icon={<MdOutlineFavoriteBorder size='20px' />}
							colorScheme='green'
							size='sm'
							onClick={() => dispatch(addToFavorites(product._id))}
						/>
					)}

					<IconButton
						onClick={handleClickToTop}
						icon={<BiExpand size='20' />}
						as={ReactLink}
						to={`/termekek/${product._id}`}
						colorScheme='green'
						size='sm'
					/>

					<Flex justify='space-between' alignItems='center'>
						{cartQty > 0 ? (
							<Flex alignItems='center' animation={`${slideIn} 0.5s ease forwards`}>
								<Button
									colorScheme='green'
									size='sm'
									onClick={decreaseQty}
									animation={`${slideIn} 0.5s ease forwards`}
									animationDelay="0.3s"
								>
									{cartQty < 2 ? <FaTrashCan /> : '-'}
								</Button>
								<Input
									type="number"
									value={cartQty}
									onChange={handleQtyChange}
									max={product.stock}
									min={1}
									width="60px"
									mx='1'
									animation={`${slideIn} 0.5s ease forwards`}
									animationDelay="0.3s"
									height=''
									fontSize='lg'

								/>
								<Button
									colorScheme='green'
									size='sm'
									onClick={increaseQty}
									animation={`${buttonSlideIn} 0.3s ease forwards`}
									isDisabled={cartQty >= product.stock}
									backgroundColor={cartQty >= product.stock ? 'green.200' : 'green.500'}  // Világosabb szín ha elérte a maximumot
									_hover={{
										backgroundColor: cartQty >= product.stock ? 'green.200' : 'green.400',  // Világosabb hover hatás
									}}
									opacity={cartQty >= product.stock ? 0.7 : 1}  // Csökkentett átlátszóság ha elérte a maximumot
									cursor={cartQty >= product.stock ? 'not-allowed' : 'pointer'}  // Nem engedett kurzor ha elérte a maximumot
								>
									+
								</Button>

							</Flex>
						) : (
							<Tooltip
								isDisabled={!cartPlusDisabled}
								hasArrow
								label={
									!cartPlusDisabled
										? 'Elérte a termék maximális mennyiségét.'
										: product.stock <= 0
											? 'Elfogyott'
											: ''
								}
							>
								<IconButton
									isDisabled={product.stock <= 0 || cartPlusDisabled}
									onClick={() => handleAddToCartClick()}
									icon={<TbShoppingCartPlus size='20' />}
									colorScheme='green'
									size='sm'
									transition="transform 0.5s ease, opacity 0.5s ease"
									transform={cartQty === 0 ? 'scale(1)' : 'scale(0)'}
								/>
							</Tooltip>
						)}
					</Flex>

				</Flex>

			</Box>
			<ConfirmAgesAlert AdultImage={AdultImage} product={product} addItem={addItem} isIsOpen={isIsOpen} onClose={() => setIsIsOpen(false)} />
			<ConfirmRemovalAlert
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				cancelRef={cancelRef}
				itemToDelete={product}
				deleteAction={deleteProduct}
			/>
		</Skeleton>
	);
};

export default ProductCard;
